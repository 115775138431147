<template>
  <v-layout column wrap>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF TIME DEPOSITS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-flex xs12 class="mx-2">
        <v-layout row wrap class="align-center">
          <v-flex xs12 md4>
            <v-select
              v-model="category"
              class="mx-3 pt-6"
              :items="['Yearly','Monthly']"
              label="Category"
              required
              dense
              @change="get_list_of_time_deposit_items"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 class="mx-2 pt-4">
        <v-layout row wrap class="align-center">
          <v-flex xs12 md4>
            <v-select
              v-if="category==='Yearly'"
              v-model="year_of"
              class="mx-3 pt-5"
              :items="year_of_items"
              item-value="year"
              item-text="year"
              label="Year Of"
              required
              dense
              @change="get_list_of_time_deposit_items"
            ></v-select>
            <v-select
              v-if="category==='Monthly'"
              v-model="month_of"
              class="mx-3 pt-5"
              :items="month_of_items"
              item-value="id"
              item-text="month_of"
              label="Month Of"
              required
              dense
              @change="get_list_of_time_deposit_items"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
                        <span class="headline" v-if="can_print">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-data-table
        :height="height"
        :headers="headers"
        :items="list_of_time_deposit"
        :search="search"
        :loading="data_table_loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <span class="subtitle-1">Amount PHP:  {{formatPrice(amount)}}</span>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <span class="subtitle-1">Interest PHP:  {{formatPrice(interest)}}</span>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <span class="subtitle-1">Total PHP:  {{formatPrice(total)}}</span>
            <v-spacer></v-spacer>

            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearchOutline"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr
          >
            <td>
              {{ item.id }}
            </td>
            <td>
              {{ item.members.cmf_no }}
            </td>
            <td>
              {{ item.members.last_name }}
            </td>
            <td>
              {{ item.members.first_name }}
            </td>
            <td>
              {{ formatPrice(item.amount) }}
            </td>
            <td>
              {{ formatPrice(Math.round(item.interest)) }}
            </td>
            <td>
              {{ formatPrice(Math.round(item.total)) }}
            </td>
            <td>
              {{ item.expected_month}}
            </td>
            <td class="text-center">
              <div v-if="item.released === 1">
                <v-chip color="success" dark>RELEASED</v-chip>
              </div>
              <div v-else>
                <v-chip color="warning" dark>PENDING</v-chip>
              </div>
            </td>
            <td>
              {{ item.date_of_withdraw}}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-layout>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import moment from "moment";
  import {mdiAccountSearchOutline, mdiPrinter} from "@mdi/js";

  export default {
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline,
          mdiPrinter,
        },
      }
    },
    data() {
      return {
        total: '0',
        interest: '0',
        amount: '0',
        can_print: false,
        selected_month_of: 'NA',
        category: '',
        month_of: '',
        year_of_items: [],
        year_of: '',
        list_of_time_deposit: [],
        month_of_items: [],
        //table props
        search: '',
        data_table_loading: false,
        headers: [
          {text: 'ID', value: 'id'},
          {text: 'CMF #', value: 'members.cmf_no'},
          {text: 'Last Name', value: 'members.last_name'},
          {text: 'First Name', value: 'members.first_name'},
          {text: 'Deposit', value: 'amount'},
          {text: 'Interest', value: 'interest'},
          {text: 'Total', value: 'total'},
          {text: 'Matured Month', value: 'expected_month'},
          {text: 'Status', value: 'expected_month'},
          {text: 'Released Date', value: 'expected_month'},
        ],
        height: 430
      }
    },
    mounted() {
      const data = new FormData()
      data.append('branch_id', this.branch_id);
      this.retrieve_transaction_month(data)
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
      this.retrieve_all_transaction_year()
        .then((response) => {
          this.year_of_items = response.data
          this.year_of_items.splice(0, 0, {
            id: this.year_of_items[0].year + 1,
            year: 'All'
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['company_logo']),
      ...mapGetters('authentication', ['branch_id', 'branch', 'branch_address', 'branch_contact_no']),
    },
    watch: {
      branch_id: function () {
        this.can_print = false
        this.month_of = ''
        this.list_of_time_deposit = []
        this.amount = '0'
        this.interest = '0'
        this.total = '0'
      }
    },
    methods: {
      ...mapActions('transaction_months', ['retrieve_transaction_month', 'retrieve_all_transaction_year']),
      ...mapActions('time_deposits', ['list_of_time_deposits']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      get_list_of_time_deposit_items() {
        if (this.category === 'Yearly') {
          if (this.year_of_items.length > 0) {
            var indexs = this.year_of_items.map(function (x) {
              return x.year
            }).indexOf(this.year_of)
            if (indexs != -1) {
              this.selected_month_of = this.year_of_items[indexs].year;
            }
          }
        } else {
          if (this.month_of_items.length > 0) {
            var index = this.month_of_items.map(function (x) {
              return x.id
            }).indexOf(this.month_of)
            if (index != -1) {
              this.selected_month_of = this.month_of_items[index].month_of;
            }
          }
        }
        if (this.selected_month_of != 'NAN') {
          if (this.selected_month_of === 'All') {
            this.selected_month_of = ''
          }
          this.data_table_loading = true
          const data = new FormData()
          data.append('month_of', this.selected_month_of);
          data.append('branch_id', this.branch_id);
          this.list_of_time_deposits(data)
            .then((response) => {
              this.can_print = true
              this.list_of_time_deposit = response.data[0].time_deposits
              this.amount = response.data[0].amount
              this.interest = response.data[0].interest
              this.total = response.data[0].total
              this.data_table_loading = false
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      print_data() {
        var imgData = this.company_logo
        var array = []
        var month = ''
        if (this.category==='Yearly'){
          var indexMonth = this.year_of_items.map(function (x) {
            return x.year
          }).indexOf(this.year_of)

          month=this.year_of_items[indexMonth].year
        }else{
          var indexMonth = this.month_of_items.map(function (x) {
            return x.id
          }).indexOf(this.month_of)

          month=this.month_of_items[indexMonth].month_of
        }
        array.push(
          [
            {text: 'ID', alignment: 'center', style: 'label'},
            {text: 'CMF #', alignment: 'center', style: 'label'},
            {text: 'Name', alignment: 'center', style: 'label'},
            {text: 'Amount', alignment: 'center', style: 'label'},
            {text: 'Interest', alignment: 'center', style: 'label'},
            {text: 'Total', alignment: 'center', style: 'label'},
            {text: 'Matured Month', alignment: 'center', style: 'label'},
            {text: 'Status', alignment: 'center', style: 'label'},
            {text: 'Released Date', alignment: 'center', style: 'label'},
          ]
        )
        if (this.list_of_time_deposit.length > 0) {
          var total_amount = this.formatPrice(this.amount)
          var total_interest = this.formatPrice(this.interest)
          var overall = this.formatPrice(this.total)
          this.list_of_time_deposit.forEach(function (item, index, payment) {
            array.push(
              [
                {text: item.id, alignment: 'left'},
                {text: item.members.cmf_no, alignment: 'left'},
                {text: item.members.last_name + ', ' + item.members.first_name, alignment: 'left'},
                {
                  text: (item.amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  alignment: 'left'
                },
                {
                  text: (item.interest / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  alignment: 'left'
                },
                {
                  text: (item.total / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  alignment: 'left'
                },
                {text: item.expected_month, alignment: 'left'},
                {text: item.released === 1?'RELEASED':'PENDING', alignment: 'left'},
                {text: item.date_of_withdraw, alignment: 'left'},
              ]
            )
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    color: 'red',
                    text: 'TOTAL: ',
                    alignment: 'left',
                    border: [false, false, false, false]
                  },
                  {
                    color: 'red',
                    text: total_amount,
                    alignment: 'left',
                    border: [false, false, false, false]
                  },
                  {
                    color: 'red',
                    text: total_interest,
                    alignment: 'left',
                    border: [false, false, false, false]
                  },
                  {
                    color: 'red',
                    text: overall,
                    alignment: 'left',
                    border: [false, false, false, false]
                  },
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                ]
              )
            }
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LIST OF TIME DEPOSITS', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            {text: 'Month of: ' + month, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [25, 45, 100, 45, 45, 45, 60,50, 60],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
